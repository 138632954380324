import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import findMyIdentity from '../components/functions/find-my-identity';
import ItemList from '../components/functions/item-list/item-list';

const PageListStructures = ({
  path,
  data: {
    allTerms: { nodes },
  },
  pageContext,
}) => {
  /**
   *
   * I WANT TO FIND WHO I AM
   * the page is trying to collect and use it's data to create own identity
   *
   */
  const nodeData = findMyIdentity(path, pageContext);
  let content;

  return (
    <Layout>
      <Seo title={nodeData.name} />
      <ItemList node={nodeData} children={nodes} />
    </Layout>
  );
};
export default PageListStructures;

export const query = graphql`
  query (
    $filter: taxonomy_term__structureFilterInput
    $sort: taxonomy_term__structureSortInput
  ) {
    allTerms: allTaxonomyTermStructure(filter: $filter, sort: $sort) {
        nodes {
          field_id
          field_type
          field_size_only_cities_p
          field_size_only_villages_p
          field_population_only_cities_p
          field_pop_only_villages_p
          field_population_total
          field_population_share_male
          field_population_share_female
          field_population_density_total
          field_population_change_1y_share
          field_population_change_5y_share
          field_population_change_alltime_
          field_avg_asl
          field_avg_mention
          field_num_of_cities
          field_num_of_villages
          field_num_of_counties
          field_num_of_districts
          field_num_of_municipalities
          field_neighbours_num
          field_size_cities
          field_size_villages
          field_population_cities
          field_population_villages
          field_p_density_cities
          field_p_density_villages
          field_population_0_14_share
          field_population_15_64_share
          field_population_65_more_share
          field_population_avg_age
          field_population_index_old_age
          field_migration_balance_total
          field_natural_increase_total
          field_total_increase_total
          field_weddings_total
          field_divorces_total
          field_migration_in_permanent_tot
          field_migration_out_permanent_to
          field_live_birth_total
          field_dead_total
          field_size_total
          field_soil_agro_total
          field_soil_agro_arable
          field_soil_agro_hops
          field_soil_agro_vineyard
          field_soil_agro_garden
          field_soil_agro_orchard
          field_soil_agro_grassland
          field_nonagro_total
          field_nonagro_forest
          field_nonagro_water_area
          field_nonagro_built_up_area
          field_nonagro_other
          field_s_agro_sh
          field_s_arable_sh
          field_s_hops_sh
          field_s_vineyard_sh
          field_s_garden_sh
          field_s_orchard_sh
          field_s_grassland_sh
          field_s_nonagro_sh
          field_s_forest_sh
          field_s_water_sh
          field_s_built_up_sh
          field_s_other_sh
          field_t_beds
          field_t_beds_1y_share
          field_t_objects
          field_t_objects_1y_share
          field_t_overstays
          field_t_overstays_1y_share
          field_t_tourists
          field_t_tourists_1y_share
          relationships {
            field_image_coa {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    height: 40
                    quality: 60
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
            }
            field_related_city {
              field_id
              title
              path {
                alias
              }
            }
          }
          id
          path {
            alias
          }
          name
        }
    }
  }
`;
